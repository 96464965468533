<template>
  <div id="supportes">
      <b-container>

      <h1 class="titles">Apoiadores</h1>

      <div class="supportes">

          <img src="@/assets/apoiador.png" alt="apoiador">
        <img src="https://cdn.congresse.me/250x0/filters:quality(100)/qxsf69f40nu7rmxuovrtq34wq2qi" alt="">
      </div>
      </b-container>
      
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
#supportes{
    text-align: center;
}
.titles {
  text-align: center;
  padding: 20px;
  display: inline-block;
  position: relative;
  margin: 0 auto;
}
.titles::after {
  position: absolute;
  content: " ";
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 2px;
  background-color: #ccc;
}
.supportes{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
}
.supportes img{
    max-width: 200px;
    object-fit: contain;
}

</style>