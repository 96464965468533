<template>
  <div id="home">
    <About></About>
    <Banner></Banner>
    <Speakers></Speakers>
    <Countdown></Countdown>
    <Schedule></Schedule>
    <Organizers></Organizers>

    <FormSteps></FormSteps>

    <Supportes></Supportes>

    <Footer></Footer>
  </div>
</template>

<script>
import Banner from "../components/Banner.vue";
import About from "../components/About.vue";
import Speakers from "../components/Speakers.vue";
import Footer from "../components/Footer.vue";
import Supportes from "../components/Supportes.vue";
import FormSteps from "../components/FormSteps.vue";
import Countdown from "../components/Countdown.vue";
import Schedule from "../components/Schedule.vue";
import Organizers from "../components/Organizers.vue";
import {mapActions} from 'vuex'
export default {
  name: "Home",
  components: {
    Banner,
    About,
    Speakers,
    Footer,
    Supportes,
    FormSteps,
    Countdown,
    Schedule,
    Organizers,
  },
  methods:{
    ...mapActions(["isLogged"]),

  },
  mounted() {
    
    localStorage.getItem("token") && this.isLogged(true);
  },
};
</script>

