<template>
  <div id="app">
    <!-- <Logo></Logo> -->
    <Navbar></Navbar>
    <router-view></router-view>
  </div>
</template>
<script>
// import { mapActions } from "vuex";
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
    // Logo
  },
  // methods: {
  //   ...mapActions(["isLogged"]),
  // },
  // mounted() {
  //   localStorage.getItem("token") && this.isLogged(true);
  // },
};
</script>

<style>
</style>
