<template>
  <div id="footer-main">
    <b-container class="footer">
      <b-row>
        <b-col>
          <img src="@/assets/logo-colorida-iph-hemoce.png" alt="" />
        </b-col>
        <b-col>
          <p>plataforma</p>
          <p>Redes Sociais</p>
        </b-col>
        <b-col class="m-auto">
          <b-button> Ajuda </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "footer-main",
};
</script>

<style scoped>
#footer-main {
 background-color: #0C2639;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
    min-height: 30vh;
    text-align: center ;
}
.footer {
  width: 100vw;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  flex-wrap: wrap;
}
#footer img {
  height: 150px;
}
</style>    