<template>
  <div id="banner">
    <div class="main-wrapper">
      <img src="../assets/campanha-doador-primeiravez.png" alt="" />
    </div>
    <div class="main-footer">
      <h1>De 24/08 a 27/08</h1>
      <h4>1º Edição do Congresso Online Nacional de Ciências Biomédicas</h4>

      <b-button pill variant="warning">Inscreva-se </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  components: {},
};
</script>
<style scoped>
#banner {
  /* color: #fff; */
  /* hei  ght: 100vh; */
  text-align: center;
}
.main-wrapper img {
  height: 70vh;
  width: 100%;
  object-fit: cover;
}
.main-footer {
  min-height: 30vh;
  margin-top: 10px;
  padding: 20px;
  background: linear-gradient(
    to right,
    rgb(211, 29, 29),
    rgb(168, 114, 14)
  );
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #fff;
}
</style>
