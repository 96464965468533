<template>
  <div id="organizers">
    <b-container>
     
     <div class="d-flex">
         
          <h1 class="titles m-auto mb-5 mt-3">Organizadores</h1>
         </div>
      <div class="organizers">
        <b-card
          v-for="organizer in organizers"
          :key="organizer.id"
          :title="organizer.title"
          :img-src="organizer.img"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-2 p-2"
        >
          <b-card-text>
            {{ organizer.text }}
          </b-card-text>

          <a href="#">
              <b-icon icon="instagram" font-scale="1.5"></b-icon>
          </a>
        </b-card>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Organizers",
  data() {
    return {
      organizers: [
        {
          id: 1,
          title: "IPH",
          text: "Pequeo texto sobre o apoiador, bla bla bla bla bla bla",
          img: "https://yt3.ggpht.com/ytc/AKedOLRnF9L-3GLKMtsS6wvEUlRLamM7EhuFM06Da2Eo=s900-c-k-c0x00ffffff-no-rj",
        },
        {
          id: 2,
          title: "Hemoce",
          text: "Pequeo texto sobre o apoiador, bla bla bla bla bla bla",
          img: "https://www.al.ce.gov.br/media/k2/items/cache/62217eaecd5cc998ded79d66f9d9787a_XL.jpg",
        },{
          id: 3,
          title: "Governo do Ceara",
          text: "Pequeo texto sobre o apoiador, bla bla bla bla bla bla",
          img: "https://www.portaliep.com/images/brasao-governo-do-estado.jpg",
        },
        // {
        //   id: 2,
        //   title: "Hemoce",
        //   text: "Pequeo texto sobre o apoiador, bla bla bla bla bla bla",
        //   img: "https://www.al.ce.gov.br/media/k2/items/cache/62217eaecd5cc998ded79d66f9d9787a_XL.jpg",
        // },{
        //   id: 2,
        //   title: "Hemoce",
        //   text: "Pequeo texto sobre o apoiador, bla bla bla bla bla bla",
        //   img: "https://www.al.ce.gov.br/media/k2/items/cache/62217eaecd5cc998ded79d66f9d9787a_XL.jpg",
        // },
      ],
    };
  },
};
</script>

<style scoped>
#organizers {
  text-align: left;
  margin-bottom: 20px;
 
}
.titles {
  text-align: center;
  padding: 30px;
  display: inline-block;
  position: relative;
  margin: 0 auto;
}
.titles::after {
  position: absolute;
  content: " ";
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 2px;
  background-color: #ccc;
}
.organizers {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.organizers .card {
    width: 250px;
    height: 400px;
    border: none;
}

.card-body {
text-align: center;
}
.card img{
    height: 200px;
    object-fit: contain;
}
/* .card-body a icon{
    text-align: left;
} */

</style>