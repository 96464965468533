<template>
  <v-form id="formSteps">
    <v-app>
      <v-row>
        <v-col cols="12" class="app px-10">
          <v-stepper v-model="currentStep" color="green">
            <v-row class="pb-5 app">
              <v-col cols="12" class="">
                <v-stepper-header class="step_tollbar">
                  <v-stepper-step
                    v-for="(step, n) in steps"
                    :key="n"
                    :complete="stepComplete(n + 1)"
                    :step="n + 1"
                    :color="stepStatus(n + 1)"
                  >
                    {{ step.name }}
                  </v-stepper-step>
                </v-stepper-header>
              </v-col>
            </v-row>

            <v-stepper-content
              v-for="(step, n) in steps"
              :step="n + 1"
              :key="n"
              class="ma-0"
            >
              <v-form ref="form" v-model="valid" lazy-validation class="p-5">
                <div v-if="currentStep == 1">
                  <h1 class="titles mt-3">Dados Pessoais</h1>

                  <v-row>
                    <v-col cols="12" md="9">
                      <v-text-field
                        v-model="form.full_name"
                        label="Nome completo:"
                        required
                      ></v-text-field>
                      <p class="red--text" v-if="$v.form.cpf.$error">
                        Esse campo é obrigatorio*
                      </p>
                    </v-col>

                    <v-col cols="12" sm="12" md="3">
                      <v-menu
                      v-model="menu"
                        ref="menu"
                        :close-on-content-click="false"
                        :return-value.sync="form.birth_date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="form.birth_date"
                            label="Data de Nascimento:"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.birth_date"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="this.$refs.menu.save(form.birth_date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>

                      
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.cpf"
                        label="CPF:"
                        v-mask="'###.###.###-##'"
                        required
                      ></v-text-field>
                      <p class="red--text" v-if="$v.form.cpf.$error">
                        Esse campo é obrigatorio*
                      </p>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-select
                        v-model="form.gender"
                        :items="genders"
                        label="Gênero"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5">
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="form.email"
                        label="E-mail:"
                        required
                      ></v-text-field>
                      <p class="red--text" v-if="$v.form.email.$error">
                        Esse email é inválido*
                      </p>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="currentStep == 2">
                  <h1 class="titles mt-3">Endereço</h1>

                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="form.cep"
                        label="CEP:"
                        v-mask="'#####-###'"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.street_name"
                        label="Rua:"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model="form.street_number"
                        label="Número:"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.complement"
                        label="Complemento:"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.neighborhood"
                        label="Bairro:"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5">
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.city"
                        label="Cidade:"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.state"
                        label="Estado:"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="currentStep == 3">
                  <h1 class="titles mt-3">Outras informações</h1>

                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="form.instituion"
                        label="Instituição de saúde:"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <!-- <v-text-field
                        v-model="form.professions"
                        label="Profissão"
                        required
                      ></v-text-field> -->

                      <v-select
                        v-model="form.profession"
                        :items="professions"
                        label="Profissão:"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                      <!-- <v-text-field
                          v-model="form.isResident"
                          label="É residente"
                          required
                        ></v-text-field> -->
                      <v-select
                        v-model="form.isResident"
                        :items="isResident"
                        label="É residente?"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.expertise"
                        label="Especialidade:"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-radio-group v-model="form.carrer" row>
                        <v-radio label="Estudante" value="student"></v-radio>
                        <v-radio label="Funcionário" value="employee"></v-radio>
                        <v-radio
                          label="Profissional da área"
                          value="area_professional"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="currentStep == 4">
                  <h1 class="titles mt-3">Finalizar Inscrição</h1>

                  <v-row v-if="form.isResident">
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="form.residential_sector"
                        label="Setor de residência:"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="form.residential_year"
                        label="Ano de residência: "
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="form.carrer == 'student'" class="mt-10">
                    <v-col cols="12" md="12">
                      <v-file-input
                        label="Enviar carteirinha ou declaração da faculdade"
                        outlined
                        dense
                        right
                      ></v-file-input>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="
                      form.carrer == 'area_professional'
                    "
                  >
                    <v-col cols="12" md="12">
                      <v-file-input
                        label="Enviar declaração da instituição ou crachá"
                        outlined
                        dense
                        right
                      ></v-file-input>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="
                      form.carrer == 'employee'
                    "
                  >
                    <v-col cols="12" md="12">
                      <v-file-input
                        label="Enviar carteira de trabalho"
                        outlined
                        dense
                        right
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </div>
              </v-form>

              <v-row class="step_tollbar ma-0 pa-0">
                <v-col
                  cols="12"
                  class="d-flex justify-center ma-0 my-3 pa-0 px-4"
                >
                  <v-btn
                    v-if="n + 1 < lastStep"
                    color="primary"
                    @click="stepsForm(n)"
                    >Próximo</v-btn
                  >
                  <v-btn v-else color="success" @click="done()">Enviar</v-btn>
                  <v-btn text v-if="currentStep != 1" @click="currentStep = n"
                    >Voltar</v-btn
                  >
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper>
        </v-col>
      </v-row>
    </v-app>
  </v-form>
</template>

<script>
import api from "../axios/axios";
import axios from "axios";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  data: () => ({
    rules: {
      1: {
        full_name: { required, minLength: minLength(3) },
        email: { required, email },
        // gender: { required },
        cpf: { required },
      },
      2: {
        cep: { required },
      },
    },
    currentStep: 1,
    lastStep: 4,
    steps: [
      { name: "Dados pessoais" },
      { name: "Endereço" },
      { name: "Outras informações" },
      { name: "Finalizar" },
    ],
    form: {
      full_name: "Jefferson leite da silva",
      email: "jefferson@gmail.com",
      gender: "",
      cpf: "60884858332",
      instituion: "",
      city: "",
      state: "",
      street_name: "",
      cep: "",
      birth_date: "",
      neighborhood: "",
      carrer: "",
      isResident: null,
    },
    menu: false,
    valid: "",
    isResident: [
      { text: "Sim", value: true },
      { text: "Não", value: false },
    ],

    genders: ["Masculino", "Feminino", "Outro"],
    professions: [
      "Médico",
      "Biomédico",
      "Farmaceutico",
      "Enfermeiro",
      "Técnico de enfermagem",
      "Auxiliar de Enfermagem",
      "Técnico de Laboratório",
      "Auxiliar de Laboratório",
      "Biólogo",
      "Outro",
    ],
  }),

  validations() {
    return {
      form: { ...this.rules[this.currentStep] },
    };
  },
  // form: {
  //   full_name: {
  //     required,
  //     minLength: minLength(4),
  //   },
  //   email: {
  //     required,
  //     email,
  //   },

  //   // gender: {
  //   //   required,
  //   // },
  //   cpf: {
  //     required,
  //   },
  //   // birth_date: {
  //   //   required,
  //   // },
  //   cep: {
  //     required,
  //   },
  //   // street: {
  //   //   required,
  //   // },
  //   // building_number: {
  //   //   required,
  //   // },
  //   // complement: {
  //   // required
  //   // },
  //   // city: {required},
  //   // state: {required},
  //   // neighborhood: {required},
  // },

  watch: {
    "form.cep": function () {
      if (this.form.cep.length == 9) {
        this.getCep();
      }
    },
  },

  methods: {
    stepsForm(n) {
      if (this.$v.$invalid) {
        console.log("com erro");
        this.$v.$touch();
      } else {
        this.currentStep = n + 2;
        console.log("sem erro", n);
      }
    },
    stepComplete(step) {
      return this.currentStep > step;
    },
    stepStatus(step) {
      return this.currentStep > step ? "green" : "blue";
    },
    done() {
      this.currenteStep = 5;
      this.form.cpf = this.form.cpf.replace(/[^\d]/g, "");
      api
        .post("/register", {
          email: this.form.email,
          password: this.form.cpf,
          ...this.form,
        })
        .then((data) => {
          // console.log("Cadastrado com sucesso", data);
          console.log(data);
          this.$router.push("/login");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCep() {
      axios
        .get(`https://brasilapi.com.br/api/cep/v1/${this.form.cep}`)
        .then((response) => {
          //coloquei o settime porque sem ele, quando aperta tab, o endereço carrega vazio
          setTimeout(() => {
            this.form.city = response.data.city;
            this.form.state = response.data.state;
            this.form.street_name = response.data.street;
            this.form.neighborhood = response.data.neighborhood;
            console.log(response.data);
          }, 1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.titles {
  text-align: center;
}
.app {
  background: linear-gradient(to right, rgb(211, 29, 29), rgb(168, 114, 14));
}
.step_tollbar {
  background-color: whitesmoke;
}
.v-stepper__header {
  flex-wrap: nowrap;
  justify-content: space-around;
}
</style>
