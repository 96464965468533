<template>
  <div id="speakers">
    <b-container>
      <h1 class="titles">Palestrantes</h1>

      <div class="speakers">
        <b-card
          img-alt="Image"
          img-top
          tag="article"
          class="mb-2"
          v-for="speaker in speakers"
          :key="speaker.id"
        >
          <b-img
            fluid
            :src="speaker.img"
            rounded="circle"
            class="img-top shadow shadow-lg--hover speaker-img"
          />
          <div class="pt-4 text-center">
            <h5 class="h3 title">
              <span class="d-block mb-1">{{ speaker.name }}</span>
              <small class="h4 font-weight-light text-muted">{{
                speaker.graduation
              }}</small>
            </h5>
          </div>
          <b-card-text>
            Algum texto pequeno sei la
            <br />caso precise
          </b-card-text>
        </b-card>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "speakers",
  data() {
    return {
      speakers: [
        {
          id: 1,
          name: "Dr. Juliana Alves",
          img: "https://www.opovo.com.br/_midias/jpg/2020/11/17/750x500/1_1_csm_anamaria_2538508aff_13977893-14105894.jpg",
          graduation: "Analista de sistemas",
        },
        {
          id: 2,
          name: "Wagner Antunes",
          img: "https://www.superprof.com.br/imagens/anuncios/professor-home-direito-civil-fortaleza-ceara-advogado-arquiteto-pos-graduando-direito-civil-processual-civil.jpg",
          graduation: "Doutorado em Segurança no w3",
        },
        {
          id: 3,
          name: "Wescley Silva",
          img: "https://static.poder360.com.br/2020/12/NelsonTeich-EduardoPazuello-Saude-11-868x644.jpg",
          graduation: "Médico Cardiologista",
        },
        {
          id: 4,
          name: "Juliana Ferreira",
          img: "https://www.opovo.com.br/noticiasimages/app/noticia_146418291334/2017/11/28/332748/doutora.jpg",
          graduation: "Doutorado em pesquisar no w3",
        },
        {
          id: 5,
          name: "Wesley safadão",
          img: "https://maranhaohoje.com/wp-content/uploads/2018/02/ronaldinho.jpg",
          graduation: "Doutorado em pesquisar no w3",
        },
      ],
    };
  },
};
</script>

<style scoped>
#speakers {
  text-align: center;
  margin-top: 15px;
}
.titles {
  text-align: center;
  padding: 30px;
  display: inline-block;
  position: relative;
  margin: 0 auto;
  margin-bottom: 50px;
}
.titles::after {
  position: absolute;
  content: " ";
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 2px;
  background-color: #ccc;
}
/* .card-speaker{
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;

}
.card-img-speaker{
    margin: 0 auto 0;
    min-width: 300px;
} */
.speakers {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  margin: 0 auto;
  flex-wrap: wrap;
}
.speakers .card {
  width: 250px;
  border: none;
}
.speakers .card img {
  object-fit: cover;
  width: 180px;
  height: 180px;

  /* 
            style="max-width: 180px;  max-height: 180px"
     */
}
</style>