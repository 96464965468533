import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
// export interface AppState {
//   user: string;
// }

export default new Vuex.Store({
  state: {
    tasks: [],
    user: "Jefferson",
    isLogged: true,
  },
  mutations: {
    ISLOGGED(state, payload) {
      state.isLogged = payload;
    },
    LOGOUT(state) {
      state.isLogged = false;
    },
  },
  actions: {
    isLogged({ commit }, loged) {
      commit("ISLOGGED", loged);
      console.log(loged)
    },
    logout({ commit }) {
      localStorage.clear();
      commit("LOGOUT");
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
   
    getAuth(state) {
      return state.isLogged;
    },
  },
  modules: {},
});
