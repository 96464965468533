import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
// import api from "../axios/axios";
// import axios from 'axios'

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // beforeEnter(to, from, next) {
    //   console.log("OI eu sou o goku");
      
    //   api.post("664/posts", {text:'blabla'}).then((resp) => {
    //     axios.defaults.headers.post["authorization"] = localStorage.token;
    //     console.log(resp)
    //   });
    //   next();
    // },
  },
  {
    path: "/enviar-resumo",
    name: "Resume",
    component: () =>
      import(/* webpackChunkName: "Resume" */ "../views/Resume.vue"),
        // meta:{requiresAuth:true},
        // beforeEnter(to, from, next){
        //   console.log(to.matched, 'aki')
        //   console.log(from)
        //   console.log(next)
        // }
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "Logout" */ "../views/Logout.vue"),
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: () =>
      import(/* webpackChunkName: "Faqs" */ "../views/Faqs.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach(({ path }, from, next) => {
  const acceptedRoutes = ["/logout", "/enviar-resumo"];

  if (acceptedRoutes.includes(path) && !store.getters["getAuth"])
    return router.push("/");
  return next();
});

export default router;
