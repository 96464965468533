<template>
  <div id="countdown">
    <div class="countdown timer p-2">
      <h1 class="titles mb-5">Está chegando o grande dia</h1>
      <flip-countdown  class="countdownflip" deadline="2021-08-24 00:00:00" :labels="{days:'Dias', hours:'Horas', minutes:'Minutos', seconds:'Segundos'}"></flip-countdown>
      <p class="mt-5">Participe do evento! Faça agora mesmo a sua inscrição</p>

      <b-button pill variant="warning" class="mb-5">INSCREVA-SE</b-button>
    </div>
  </div>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
export default {
  name: "Countdown",
  components: { FlipCountdown },
};
</script>

<style scoped>
  .countdown {
    padding-top: 50px;
    background: #004d40;
    text-align: center;
    width: 100%;
  }
.titles {
  text-align: center;
  padding: 30px;
  display: inline-block;
  position: relative;
  margin: 0 auto;
}
.titles::after {
  position: absolute;
  content: " ";
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 2px;
  background-color: #ccc;
}

  .timer {
    font-size: 20px;
    color: #fff;
    text-align: center;
    }

  








</style>