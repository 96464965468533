<template>
  <div id="schedule">
    <b-container>
      <h1 class="titles">Programação</h1>
      <p>(Programação sujeita a alterações)</p>
      <div class="buttons">
        <span
          @click="isActiveTrue"
          class="activeDay"
          :class="show == true ? 'activeDay' : 'disableDay'"
          >06/08/21</span
        >
        <span
          @click="isActiveFalse"
          class="activeDay"
          :class="show == true ? 'disableDay' : 'activeDay'"
          >07/08/21</span
        >
      </div>
      <div class="content" v-if="show == true">
        <section>
          <h3>09:00</h3>
          <h4>PAINEL 3 - Políticas públicas: responsabilidade de todos nós</h4>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veritatis,
            expedita aliquid. Dicta alias harum quisquam recusandae inventore
            officia modi nobis.
          </p>
          <p>
            <small>Tiririca e Wesley Safadão</small>
          </p>
          <b-button variant="warning">Acessar conteudo</b-button>
        </section>
        <hr>

        <section>
          <h3>10:30</h3>
          <h4>MESA 3 - Transplante pulmonar: uma oportunidade de recomeço</h4>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veritatis,
            expedita aliquid. Dicta alias harum quisquam recusandae inventore
            officia modi nobis.
          </p>
          <p>
            <small>Tiririca e Wesley Safadão</small>
          </p>
          <b-button variant="warning">Acessar conteudo</b-button>
        </section>
        <hr>

        <section>
          <h3>12:30</h3>
          <h4>MESA 4 - Os desafios da fibrose cística na vida adulta</h4>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veritatis,
            expedita aliquid. Dicta alias harum quisquam recusandae inventore
            officia modi nobis.
          </p>
          <p>
            <small>Tiririca e Wesley Safadão</small>
          </p>
          <b-button variant="warning">Acessar conteudo</b-button>
        </section>
      </div>

      <div class="content" v-else>
         <section>
          <h3>10:00</h3>
          <h4>PAINEL 4 - Autocuidado e empoderamento do paciente</h4>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veritatis,
            expedita aliquid. Dicta alias harum quisquam recusandae inventore
            officia modi nobis.
          </p>
          <p>
            <small>Tiririca e Wesley Safadão</small>
          </p>
          <b-button variant="warning">Acessar conteudo</b-button>
        </section>
        <hr>

        <section>
          <h3>12:30</h3>
          <h4>PAINEL 3 - Políticas públicas: responsabilidade de todos nós</h4>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veritatis,
            expedita aliquid. Dicta alias harum quisquam recusandae inventore
            officia modi nobis.
          </p>
          <p>
            <small>Tiririca e Wesley Safadão</small>
          </p>
          <b-button variant="warning">Acessar conteudo</b-button>
        </section>
        <hr>

        <section>
          <h3>12:30</h3>
          <h4>PAINEL 3 - Políticas públicas: responsabilidade de todos nós</h4>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veritatis,
            expedita aliquid. Dicta alias harum quisquam recusandae inventore
            officia modi nobis.
          </p>
          <p>
            <small>Tiririca e Wesley Safadão</small>
          </p>
          <b-button variant="warning">Acessar conteudo</b-button>
          
        </section>
        <section>
        <h3>10:30</h3>
          <h4>PAINEL 3 - Políticas públicas: responsabilidade de todos nós</h4>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veritatis,
            expedita aliquid. Dicta alias harum quisquam recusandae inventore
            officia modi nobis.
          </p>
          <p>
            <small>Tiririca e Wesley Safadão</small>
          </p>
          <b-button variant="warning">Acessar conteudo</b-button>
        </section>
        
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    };
  },
  methods: {
    isActiveTrue() {
      this.show = true;
    },
    isActiveFalse() {
      this.show = false;
    },
  },
  watch: {
    show() {
      return this.show;
    },
  },
};
</script>

<style scoped>
.buttons {
  width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
}
.activeDay {
  background-color: #F8C10E;
  color: #000 ;
  border-radius: 50rem;
}
.disableDay {
  background-color: black;
  color: #fff;
}
.activeDay,
.disableDay {
  padding: 10px 15px;
}
.content section{
  padding: 15px 0;
}
#schedule {
  text-align: center;
  background: linear-gradient(to right, rgb(211, 29, 29), rgb(168, 114, 14));
  padding: 20px;
  color: #fff;
}
.titles {
  text-align: center;
  padding: 30px;
  display: inline-block;
  position: relative;
  margin: 0 auto;
  margin-bottom: 50px;
}
.titles::after {
  position: absolute;
  content: " ";
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 2px;
  background-color: #ccc;
}
section {
  text-align: left;
}
</style>