<template>
  <div id="about">
    <b-container>
      <!-- <h1 v-if="this.$store.state.isLogged">Usuario Logado</h1>
      <h1 v-else>Usuario não logado</h1>
      <h1 class="titles">Sobre o Evento</h1> -->
      <p class="content-about">
        Seja bem-vindo(a) ao CONACIB!
        <br />
        O Congresso Online Nacional de Ciências Biomédicas te convida a
        participar de uma imersão em conhecimento sobre a área Biomédica que tem
        se mostrado tão importante e que está em fase de constante expansão.
        Esta profissão conta com mais de 35 áreas de atuação e abordaremos
        assuntos relacionados com a Perícia criminal, Biomedicina estética,
        Imaginologia, Hematologia, Análises Clinicas, entre outros.
        <br />

        O CONACIB acontecerá entre os dias 30 de agosto a 01 de setembro de
        2021, abordando temas relevantes e integrados ao cenário atual. O evento
        conta com a participação de incríveis palestrantes da área,
        compartilhando conhecimentos e permitindo a troca de experiências entre
        Biomédicos de todo o Brasil.
        <br />

        O evento possui edital de envio de resumos técnico-científicos de
        estudos e trabalhos aplicados a área para publicação com ISBN atrelado
        aos anais do congresso. As linhas de conhecimento aceitas estão
        presentes no edital de submissão e o envio do trabalho é gratuito.
        Clique aqui e aproveite essa oportunidade! 📝 O CONACIB será realizado
        nos dias 30 de agosto a 01 de setembro de 2021 e a INSCRIÇÃO É GRATUITA!
      </p>
      <b-button pill variant="danger mt-5 mb-5" to="/faqs">Perguntas frequentes</b-button>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#about {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f8fb;

  margin-top: 10px;
}
.titles {
  text-align: center;
  padding: 30px;
  display: inline-block;
  position: relative;
  margin: 0 auto;
}
.titles::after {
  position: absolute;
  content: " ";
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 2px;
  background-color: #ccc;
}
.content-about {
  width: 80%;
  margin: 0 auto;
  text-align: left;
  margin-top: 20px;
}
</style>