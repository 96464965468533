<template>
  <div id="navbar">
    <b-navbar toggleable="lg" class="nav mb-3">
      <b-img
        src="https://www.prohemo.org.br/assets/image/logo/logo-colorida-iph-hemoce.png"
        class="m-auto"
      ></b-img>

      <b-navbar-toggle class="m-auto" target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="mt-3">
          <b-nav-item to="/" @click="scroll('speakers')">
            Palestrantes
          </b-nav-item>
          <b-nav-item to="/" @click="scroll('schedule')">
            Programação
          </b-nav-item>
          <b-nav-item to="/enviar-resumo" v-if="isLogged">
            Submição de resumos simples e expandido
          </b-nav-item>
          <!-- <b-nav-item>Produtos</b-nav-item>   -->
          <b-nav-item to="/" @click="scroll('formSteps')" v-if="!isLogged"
            >Inscreva-se</b-nav-item
          >
          <b-nav-item to="/login" v-if="!isLogged">Login</b-nav-item>
          <b-nav-item to="/logout" v-if="isLogged">Logout</b-nav-item>
          <b-nav-item to="/faqs">Perguntas frequentes</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import store from "../store/index";
export default {
  data() {
    return {};
  },
  computed: {
    isLogged() {
      return store.state.isLogged;
    },
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
      // console.log(this.isLogged);
    },
  },
};
</script>

<style scoped>
/* #navbar{
    display: flex;
    justify-content: center;
    align-items: center;
} */
.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* a:hover{
    color: red  !important;;
} */
@media screen and (max-width: 991px) {
  .nav {
    flex-direction: row;
  }
  #nav-collapse {
    margin-left: 50px;
  }
}
</style>